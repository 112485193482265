import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const Popup = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    /*
    const timerDelay = setTimeout(() => {
      document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      setOpen(true);
    }, 300);
    return () => clearTimeout(timerDelay);
    */

    document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    setOpen(true);
  }, []);

  const handleClose = (e) => {
    document.getElementsByTagName("HTML")[0].style.overflow = "auto";
    document.body.style.overflow = "auto";
    setOpen(false);
  };

  return (
    <div className={open ? "modal open" : "modal"}>
      <div className="modal-close">
        <button type="button" onClick={(e) => handleClose(e)}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
      </div>
      <div className="modal-content-container" onClick={(e) => handleClose(e)}>
        <div className="modal-content">
          <img
            src="https://cme-qnewmedia.netdna-ssl.com/wp-content/uploads/2020/06/logo.svg"
            alt="CME Group Tour Championship Logo"
          />
          <p>
            Information regarding the 2020 CME Group Tour Championship coming
            soon.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Popup;
